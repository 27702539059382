import React, { Component } from "react";
import Navbar from "./Components/navBar/navbar";
import Section from "./Components/section/section";
import Contactus from "./Components/contactus/Contactus";
import ReactGa from "react-ga";
import { FaArrowUp } from "react-icons/fa";

class App extends Component {
  state = {
    windowPosition: window.pageYOffset,
    display: "d-none",
  };

  handleScroll() {
    this.setState({ windowPosition: window.pageYOffset });
    if (this.state.windowPosition > 400) {
      this.setState({ display: "" });
    } else {
      this.setState({ display: "d-none" });
    }
  }
  componentDidMount() {
    ReactGa.initialize("2755479382");
    ReactGa.pageview("/");
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Section />
        <Contactus />
        <a href="#navbar" className={this.state.display}>
          <FaArrowUp id="up" className="ArrowUP" />
        </a>
      </React.Fragment>
    );
  }
}

export default App;
