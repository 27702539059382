import React, { Component } from "react";
import "./navBar.css";

import Helmet from "react-helmet";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      i: 1,

      localImageURL: "/images/02.png",
    };
  }
  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
          <a
            href="https://www.facebook.com/egyconvention/"
            target="_blank"
            rel="noreferrer"
            className="navbar-brand text-success fw-bolder "
          >
            <img id="logo" src={this.state.localImageURL} alt="this is logo" />
          </a>
          <button
            className="navbar-toggler btn btn-outline-danger"
            type="button"
            data-bs-target="#toggleMenu"
            data-bs-toggle="collapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="toggleMenu">
            <ul className="navbar-nav text-center ms-auto ">
              <li className="nav-item  mx-3">
                <a href="#home" id="head-text" className="nav-link">
                  <strong>Home</strong>
                </a>
              </li>
              <li className="nav-item mx-3">
                <a href="#contactus" className="nav-link" id="head-text">
                  <strong>Join us</strong>
                </a>
              </li>
              <li className="nav-item mx-3">
                <a
                  href="https://www.facebook.com/egyconvention/about"
                  className="nav-link"
                  id="head-text"
                  target="_blank"
                >
                  <strong>About us</strong>
                </a>
              </li>
              <li className="nav-item mx-3">
                <a href="#contactus" className="nav-link" id="head-text">
                  <strong>Contact us</strong>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav text-center ms-auto ">
              <li className="nav-item ">
                <a
                  href="https://www.facebook.com/egyconvention/"
                  target="_blank"
                >
                  <img src="images/Facebook.png" className="m-2 " />
                </a>

                <a
                  href="https://www.instagram.com/egycon.me/?hl=en"
                  target="_blank"
                >
                  <img src="images\Instagram.png" className="m-2" />
                </a>

                <a href="https://twitter.com/egypt_con" target="_blank">
                  <img src="images\Twitter.png" className="m-2" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
