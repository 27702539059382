import React, { useState, useRef, useEffect } from "react";
import "./section.css";

const Section = () => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    const countdownDate = new Date("mar 17, 2023 14:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        //stop our timer
        clearInterval(interval.current);
      } else {
        //update timer
        setTimerDays(days < 10 ? "0" + days : days);
        setTimerHours(hours < 10 ? "0" + hours : hours);
        setTimerMinutes(minutes < 10 ? "0" + minutes : minutes);
        setTimerSeconds(seconds < 10 ? "0" + seconds : seconds);
      }
    }, 1000);
  };

  //componentDidMount
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <div className="section" id="home">
      <div className="timer">
        <div>
          <section>
            <p className="leftdn">{timerDays}</p>
            <p className="leftd">Days</p>
          </section>
          <span>:</span>
          <section>
            <p className="lefthn">{timerHours}</p>
            <p className="lefth">Hours</p>
          </section>
          <span>:</span>
          <section>
            <p className="leftmn">{timerMinutes}</p>
            <p className="leftm">Minutes</p>
          </section>
          <span>:</span>
          <section>
            <p className="left">{timerSeconds}</p>
            <p className="lefts">Seconds</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Section;
