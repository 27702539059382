import "./contactus.css";
import { ImageMap } from '@qiuz/react-image-map';


const contactus = () => {
  const img = '/images/1440x bra7ty.jpg';

const mapArea = [
  {
    left: '10%',
    top: '80%',
    height: '2%',
    width: '39%',
    href:'mailto:info@egycon.org',
    style: { cursor: 'pointer' },
    target:"_blank",
  },
  {
    left: '10%',
    top: '90%',
    height: '2%',
    width: '39%',
    href:'mailto:i.risha@egycon.org',
    style: { cursor: 'pointer' },
    target:"_blank",
  },
  {
    left: '10%',
    top: '96%',
    height: '2%',
    width: '39%',
    href:'mailto:m.abdelnaser@egycon.org',
    style: { cursor: 'pointer' },
    target:"_blank",
    
  },
  
];

const onMapClick = (area, index) => {
	const tip = `click map${index + 1}`;
  
  window.open(area.href);

}

      
 
    return ( <div>

<ImageMap
	className="usage-map"
	src={img}
	map={mapArea}
  id="test"
	onMapClick={onMapClick}
/>
{/* <img src="/images/1440x bra7ty.jpg" alt="this is contactus"  usemap="#workmap"></img>  


<map name="workmap">
  <area shape="rect" coords="180,2090,600,2120" alt="Computer" href="mailto: info@egycon.org" target="_blank"/>
  <area shape="rect" coords="90,2355,600,2400" alt="Computer" href="mailto:i.risha@egycon.org" target="_blank"/>
  <area shape="rect" coords="180,2500,700,2550" alt="Computer" href="mailto:m.abdelnaser@egycon.org" target="_blank"/>
 
</map> */}
<p id="contactus"></p>
  </div> );
}
 
export default contactus;